
.combobox-sucursal-mv { 
    width: 271px; 
}

.combobox-proveedor-mv {
    width: 271px; 
}

.combobox-clase-mv { 
    width: 98px;    
}

.combobox-categoria-mv{
    width: 250px;
}

.combobox-Segmento-mv{
    width: 184px;    
}

.combobox-tipoFactura-mv{
    width: 50px;
}


.combobox-empresa-mv {
    width: 184px;     
}

.input-fechaDesde-mv {
    width: 250px
}

.input-fechaHasta-mv { 
    width: 200px;
}

.combobox-tipoFactura-mv {
    width: 200px;
}

.contenedor-principal{
    background-color: white;
    height: 100%;
    width: 100%;
}

.titulo-principal{
    text-align: center;
    padding: 10px;

}

.contenedor-tabla-mv{
    height: 100% ;/* Ajusta según la altura de tu encabezado */
    overflow-y: auto;
    width: 100%;
}

.contenedor-parametros-margenesVentas{
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
}

.contenedor-parametros-fila-mv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;    
}


@media (max-width: 1100px) {

    .contenedor-parametros-margenesVentas{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;    
    }

    .contenedor-parametros-fila-mv{
        padding: 5px 10px;
        display: flex;
        flex-direction: column;        
        align-content: normal;
        justify-content: space-between;
        align-items: stretch;
        padding-inline: 10px;
    }

    
.combobox-sucursal-mv { 
    width: 100%; 
}

.combobox-proveedor-mv {
    width: 100%; 
}

.combobox-clase-mv { 
    width: 100%;    
}

.combobox-categoria-mv{
    width: 100%;
}

.combobox-Segmento-mv{
    width: 100%;    
}

.combobox-tipoFactura-mv{
    width: 100%;
}


.combobox-empresa-mv {
    width: 100%;     
}

.input-fechaDesde-mv {
    width: 100%
}

.input-fechaHasta-mv { 
    width: 100%;
}


}
