.tom {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 10px 10px 10px;
}

.tomTitulo {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;

  /* position: absolute; */
  text-transform: uppercase;
  background-image: linear-gradient(-250deg, #12c2e9 0%, #c471ed 50%, #f64f59 100% );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: bgText 2.5s linear infinite;
}

@keyframes bgText{ 
  to{
    background-position: 200% center;
  }
}

.tomContenedor {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  overflow: auto;
  min-width: 900px;
}

.tomAleatorio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ruleta {
  border-radius: 10px;
  grid-row: 1/4;
}

.tabla {
  overflow: auto;
  grid-column: 1/3;
}

.rifa {
  grid-row: 1/4;
  padding: 0;
  overflow: hidden;
}

/*---------------------------------Giro tombola------------------------------*/
.contenedorCajasTom {
  display: "flex";
  flex-flow: row;
  flex-wrap: wrap;
  /* background-color: blueviolet; */
  padding-left: 5px;
}

.slot {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 45px;
}

section {
  position: absolute;
  border-radius: 15px !important;
  border: 3px solid black !important;
  width: 40px;
  height: 70px;
  overflow: hidden;
  background-color: grey;
  border-radius: 2px;
  border: 1px solid lightgrey;
  color: white;
  font-family: sans-serif;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: default;
}

.container {
  position: absolute;
  top: 2px;
  width: 100%;
  transition: top linear 0.4s;
  text-align: center;
}



.roll {
  width: 100%;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  overflow: auto;
}

.rolling {
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    /*color: #000;*/
  }
  49% {
    /*color: #000;*/
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    /* color: #000;*/
  }
}
 
/*-------------------------------------------------------*/

/*------------Seleccion del tipo de rifa-----------------*/
.custom-input {
  width: 100%;
  margin-bottom: 5px;
}

.custom-input .input {
  outline: none;
  display: block;
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  appearance: none;
  background-color: transparent;
}
/*----------------------------------------------------------*/
.logoHalcon {
  height: 100%;
  width: 350px;
}

.logoHalcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.tomNombreGanador {
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;

  /* position: absolute; */
  text-transform: uppercase;
  background-image: linear-gradient(-250deg, #FFE800 0%, #235CFF 50%, #f64f59 100% );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: bgText 2.5s linear infinite;
}

@media screen and (max-width: 900px) {
  .tomContenedor {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    min-width: 0;
  }

  .ruleta {
    grid-column: 1/2;
    grid-row: 1/7;
  }

  .rifa {
    display: none;
    /* grid-column: 1/2;
    grid-row: 5/7;
    justify-content: flex-end;
    padding-bottom: 0; */
  }

  .tabla {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: auto;
    grid-row: 7/9;
    /* display: flex;
    align-items: flex-start; */
    grid-column: 1/2;
    justify-content: flex-start;
  }

  .logoHalcon {
    display: none;
  }

  .tomTitulo {
    display: none;
  }

  section {
    overflow: auto;
  }
}
