.contenedor-encabezado {
    text-align: center;
    padding: 20px;
}

.separador{
    border: 2px solid #ccc;
}

.encabezado-tabla {
    background-color: #4682b3;
   
}

.encabezado-tabla-detalle{
    background-color: #1a196f;
    font-weight: bold;
   
}

.tabla-totales{
    background-color: #6e6e6e;
}
