

.contenedor-encabezado-tmv {
    text-align: center;
    padding: 10px;
}

.fila-contenedor-encabezado-tmv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.encabezado-meta {
    margin-left: auto;
    margin-right: 50px;
}

.encabezado-fechas {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.separator-tmv {
    border: 2px solid #ccc; 
}

.contenedor-tabla-tmv {
    padding: 0px 10px;    
    overflow-y: auto;
}



.totalPrincipal-tabla-tmv {
    background-color: #191970;

}

.totalSecundario-tabla-tmv {
    background-color: #6f6f6f;
}

@media (max-width: 1000px) {
    .fila-contenedor-encabezado-tmv{
        display: block;
        text-align: center;    
    }  
    
    .encabezado-fechas {
        position: static; 
        transform: none;
        margin-bottom: 10px;
    }

    .encabezado-meta {
        margin: 0 auto;
    }
}