/*Titulo*/
.contenido_titulo {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}

/*Tarjetas*/
.targeta {
  height: 300px;
  box-shadow: 0 0 5px 1px rgba(80, 77, 77, 0.2);
  border-radius: 5px;
  transition: all 400ms;
}

.targeta:hover {
  box-shadow: 0 0 5px 5px rgba(80, 77, 77, 0.2);
}

.tarj {
  width: 100%;
  height: 200px;
  overflow: auto;
  margin-bottom: 20px;
}
