.contenedor-principal{
    background-color: white;
    height: 100%;
    width: 100%;
}

.titulo-principal{
    text-align: center;
    padding: 10px;

}

.contenedor-parametros{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.combobox-sucursal { 
    width: 271px;
   
}

.combobox-empresa {
    width: 114px;
}


@media (max-width: 767px) {
    .contenedor-parametros{
        padding: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: normal;
        justify-content: center;
        align-items: stretch;
        padding-inline: 10px;
    }

    .combobox-sucursal{
       width: 100%;
       
    }

    .combobox-empresa {
        width: 100%;
    } 

}


