

#tableDelPrecio {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 5px;
  table-layout: fixed;
  min-width: 1470px;
}

#tableDelPrecio caption {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 2px 0px;
}

.tableDel-encabezado-fila{
  padding: 2px;
  top: 0;
}

.tableDel-encabezado-tabla {
  text-transform: uppercase;
  background-color:#c9c8c8;
  
  color: #fff;
  top: 0;
  padding: 2px;
}  



.tableDel-encabezado-tabla-segmentos{
  text-transform: uppercase;
  background-color: #949191;
  
  color: #fff;
  top: 0;
  padding: 2px;
}

.fila{
  border: 1px solid #d1cccc;
  width: 100%;
  
}

.celda {
  text-align: center;
  
}

.contenedor-tipos{
  display: flex;
}

.celda-tipo {
  width: 100%;
  text-align: left;
}

.celda-monto {
  width: 100%;
  text-align: right;
}


#tableDelPrecio td {
  padding: 0px;
  
}

#tableDelPrecio tr {
  /*background-color: #f8f8f8;*/
  border: 1px 0px 1px 0px solid #ddd;
  width: 100%;
}

#tableDelPrecio tbody tr:hover {
  background-color: rgba(121, 116, 116, 0.2);
}

#tableDelPrecio tbody td:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
/* 
@media screen and (width < 900px) {
  #tableDelPrecio {
    border: 0px;
  }
  #tableDelPrecio thead {
    display: none;
  }

  #tableDelPrecio tr {
    margin-bottom: 8px;
    border-bottom: 4px solid #ddd;
    display: block;
  }

  #tableDelPrecio td {
    display: block;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  #tableDelPrecio td:last-child {
    border-bottom: 0px;
  }
  #tableDelPrecio td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    float: left;
  }
} */
