.contenido {
  background: linear-gradient(rgba(5, 7, 12, 0.75), rgba(5, 7, 12, 0.75)), url("../recursos/fondoLogin.webp") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.envoltura {
  position: relative;
  max-width: 430px;
  width: 100%;
  background-color: transparent;
  padding: 34px;
  border-radius: 6px;
  margin: 10px;
  text-align: center;
}

.envoltura form {
  margin-top: 30px;
}

.envoltura form .input-box {
  height: 52px;
  margin: 20px 0;
}

.input-box.button input {
  color: #fff;
  letter-spacing: 1px;
  border: none;
  background: #116cd6;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0 20px;
  font-size: 1.2rem;
  font-weight: 400;

}

.input-box.button input:hover {
  background: #0557b4;
}
.contenedorLogo{
  width: 100%;
}

.contenedorLogo .logo{
  width: 70%;
}