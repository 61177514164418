


.TicketHome-Contenedor-Content{
    background-color: #FFFFFF;
    width: 100%;
    flex-grow: 1;
    height: 100%;
}


.TicketHome-Contenedor-Content-Image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}


.TicketHome-Contenedor-Content-Image img {
   width: 825px;
   height: 248px;
    
}