.contenedor {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(5, 7, 12, 0.75), rgba(5, 7, 12, 0.25)) ,url('../recursos/fondoLogin.webp') no-repeat center center fixed ;
    background-size: cover;
    text-align: center;
}

.inicio-titulo {
    margin: 10px;
    /* position: absolute; */
    text-transform: uppercase; 
    background-image: linear-gradient(
      -250deg,
      #fff 0%,
      #fff 50%,
      #f64f59 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    /* text-fill-color: transparent; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: bgText 3s linear;
  }