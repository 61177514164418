.CrearTicket-ContenedorPrincipal {
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
}

.CrearTicket-ContenedorSombra {   
    margin: 20px; 
    width: 97%;
    height: 94%;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.Contenedor-Formulario{
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 15px; 
   
}

.CrearTicket-Titulo { 
    display: flex;
    flex-direction: row;
    gap: 8px;
}