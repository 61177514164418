.contenedor-encabezado{
    text-align: center;
    padding: 20px;
}

.contenedor-tabla-secundaria{
    text-align: center;
    padding: 30px;
}

.titulo-tabla-secundaria{
    margin: 10px;
}

.encabezado-tabla {
    background-color: #4682b3;
    color: white;
}

.encabezado-tabla-detalle{
    background-color: #1a196f;
    font-weight: bold;
   
}

.tabla-totales{
    background-color: #6e6e6e;
}

.contenedor-tabla-principal {
    margin: 20px;
    text-align: center;
}