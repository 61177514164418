.contenedorPrin {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.cajaNormal {
  grid-column: 1/3;
}

.contenedor-tabla {
  padding: 10px 0px 0px 0px;
  width: 100%;
  overflow-x: auto;
  margin: 0;
  
  
}

.dialog {
  width: 100%;
}

@media screen and (width < 900px) {
  .codigo {
    font-weight: 800;
  }
  /********************/
  .contenedorPrin {
    grid-template-columns: 1fr;
  }
  .caja {
    grid-column: 1/3;
  }
}
