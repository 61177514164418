.sideMenu-ContenedorPrincipal {
    display: flex;
    flex-direction: column;
    background-color: #F9F9F9;
    width: 300px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    height: 100%;
  
}


.sideMenu-ContenedorPrincipal-CreateShortcut {
    height: 30%;
}

.div-CreateShortcut {
    margin: 50px 30px;
    width: 100%;
    height: 100%;
}

.sideMenu-ContenedorPrincipal-ButtonsMenu {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    height: 70%;
}