.contenedor-table {
  max-width: 100%;
  max-height: 200px;
  overflow: auto;
  position: sticky;
  top: 0;
  grid-column: 1/3;
}

#tableDel {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 5px;
  table-layout: fixed;
}

#tableDel caption {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 2px 0px;
}

#tableDel tr {
  /*background-color: #f8f8f8;*/
  border: 1px solid #ddd;
}

#tableDel th,
#tableDel td {
  padding: 2px;
  text-align: center;
} /*23283e*/

#tableDel thead th {
  text-transform: uppercase;
  background-color: #7a7676;
  position: sticky;
  color: #fff;
  top: 0;
  padding: 2px;
}
#tableDel tbody tr td {
  border: 1px solid #d1cccc;
}
#tableDel tbody tr:hover {
  background-color: rgba(121, 116, 116, 0.2);
}

#tableDel tbody td:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}

@media screen and (width < 900px) {
  #tableDel {
    border: 0px;
  }
  #tableDel thead {
    display: none;
  }

  #tableDel tr {
    margin-bottom: 8px;
    border-bottom: 4px solid #ddd;
    display: block;
  }

  #tableDel td {
    display: block;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  #tableDel td:last-child {
    border-bottom: 0px;
  }
  #tableDel td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    float: left;
  }
}
